import React, { Component } from 'react';
import SubColor from './subColor.js';
import Colors from './Colors.js'
import ColorController from './ColorController.js';


class MainColor extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      dataModel: Colors,
      selectedMode: 'lessName',
      background: '#fff'
    }
  }

  handleModeChange(e) {
    this.setState({selectedMode: e.target.id});
  }

  changeBackground(color) {
    this.setState({background: color})
  }

  render() {
    return (
      <div className="background" style={{backgroundColor: this.state.background}} >
      <ColorController handleChange={this.handleModeChange.bind(this)} getMode={this.state.selectedMode} setBackgroundColor={this.changeBackground.bind(this)}/>
      <div className="colorStrips">
      {
      this.state.dataModel.map((color, index) =>
        <div className="color" key={index}>
          <div className="mainColor">
            <div className={"color-sample c-" + color.lessName + "100"}>
              <h6>{color.lessName}</h6>
              <p>RGBA: {color.colorValue}</p>
              <p>SCSS: ${color.lessName}100</p>
              </div>
          </div>
            <SubColor MainColor={color.lessName} SelectedMode={this.state.selectedMode} setBackgroundColor={this.changeBackground.bind(this)}/>
        </div>
        )
      }
      </div>
      </div>
    );
  }
}

export default MainColor;