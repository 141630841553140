import React, { Component } from 'react';

import SearchByHex from './SearchByHex'
class ColorController extends Component {

  render() {
    return (
      <div className="modus">
        <label>
          <input type="radio" id="rgb" name="rgb Modus" checked={this.props.getMode === 'rgb'} onChange={this.props.handleChange.bind(this)} />
          RGB
        </label>
        <label>
          <input type="radio" id="hex" name="hex Modus" checked={this.props.getMode === 'hex'} onChange={this.props.handleChange.bind(this)} />
          HEX
        </label>
        <label>
          <input type="radio" id="lessName" name="lessName Modus" checked={this.props.getMode === 'lessName'} onChange={this.props.handleChange.bind(this)} />
          SCSS Name
        </label>
        <SearchByHex setBackgroundColor={this.props.setBackgroundColor.bind(this)}/>
      </div>
    );
  }
}

export default ColorController;