import React, { Component } from 'react';

class SubColor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bgColor: ''
    }
  }


  rgb2hex(rgba) {
     var rgb = rgba.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
     return (rgb && rgb.length === 4) ? "#" +
      ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
      ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
      ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
  }

  mouseOver = (e) => {
    const backgroundColor = window.getComputedStyle(e.target, null).getPropertyValue("background-color");

    if(this.props.SelectedMode === 'rgb') {
      this.setState({bgColor: backgroundColor});
    }
    else if(this.props.SelectedMode === 'hex'){
      this.setState({bgColor: this.rgb2hex(backgroundColor)});
    }
    else if(this.props.SelectedMode === 'lessName'){
      this.setState({bgColor: e.target.id});
    }
  }

  copyColor = (e) => {
    var textField = document.createElement('textarea')
    const backgroundColor = window.getComputedStyle(e.target, null).getPropertyValue("background-color");

    if(this.props.SelectedMode === 'rgb') {
      textField.innerText = backgroundColor;
    }
    else if(this.props.SelectedMode === 'hex'){
      textField.innerText = this.rgb2hex(backgroundColor).split('#')[1];
    }
    else if(this.props.SelectedMode === 'lessName'){
      textField.innerText = e.target.id;
    }
    this.props.setBackgroundColor(this.rgb2hex(backgroundColor));
    document.body.appendChild(textField)
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }

  render() {
    const {MainColor} = this.props;

    var colors = [];
    for (var i = 100; i >= 2 ; i--) {
      if(i % 10 === 0){
        colors.push(<div id={MainColor + i} className={"sub-color-sample c-" + MainColor + i} key={i} onMouseEnter={this.mouseOver} onClick={this.copyColor}><div className={"show-value c-"  + MainColor + i}>{this.state.bgColor.toUpperCase()}<br/>{i + "%"}</div></div>);
      }
      if(i === 5){
        colors.push(<div id={MainColor + "0" + i} className={"sub-color-sample c-" + MainColor + "0" + i} key={i} onMouseEnter={this.mouseOver} onClick={this.copyColor}><div className={"show-value c-"  + MainColor + "0" + i}>{this.state.bgColor.toUpperCase()}<br/>{i + "%"}</div></div>);
      }
      if(i === 3){
        colors.push(<div id={MainColor + "0" + i} className={"sub-color-sample c-" + MainColor + "0" + i} key={i} onMouseEnter={this.mouseOver} onClick={this.copyColor}><div className={"show-value c-" + MainColor + "0" + i}>{this.state.bgColor.toUpperCase()}<br/>{i + "%"}</div></div>);
      }
    }

    return (
      <div className="sub-color-rectangle">
        {colors}
      </div>
    );
  }
}

export default SubColor;