import React, { Component } from 'react';
import './App.css';
import MainColor from './mainColor.js';


class App extends Component {
  render() {
    return (
      <MainColor>
      </MainColor>
    );
  }
}

export default App;
