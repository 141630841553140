import React, {Component} from 'react';
import JanColors from '@janitza/websdk-lib-colors/dist/colors.scss';
import _ from 'underscore';

function getHex(gr1) {
    let result = gr1.toString(16).toUpperCase();
    return result.length === 1 ? "0" + result : result;
}

function getHexColor(r, gr1, gr2, gr3) {
    r.push("#" + getHex(gr1) + getHex(gr2) + getHex(gr3))
}

class JanColor {
    constructor(name, distanceSum) {
        this.name = name;
        this.distanceSum = distanceSum;
    }
}

function getGroupNumber(hexColor, grNumber) {
    return parseInt(hexColor.substring(grNumber * 2 - 1, grNumber * 2 + 1), 16);
}

function getDistance(janColorHex, colorHex) {
    let sum = 0;
    for (let grNr = 1; grNr <= 3; ++grNr) {
        sum += Math.abs(getGroupNumber(janColorHex, grNr) - getGroupNumber(colorHex, grNr));
    }
    return sum;
}

class SearchByHex extends Component {
    constructor(props) {
        super(props);
        this.state = {foundLessName: null}
        this.searchHex = this.searchHex.bind(this);
    }

    searchHex(event) {
        this.props.setBackgroundColor(event.target.value)
        let searchHex = event.target.value;
        searchHex = searchHex.toUpperCase();

        if (searchHex.length >= 1 && searchHex[0] !== '#')
            searchHex = '#' + searchHex;

        const found =
            Object.keys(JanColors).filter(e => {
                return (JanColors[e] === searchHex)
            });
        if (found.length === 1)
            this.setState({foundLessName: found[0]});
        else {
            const suggestionArr = _.sortBy(Object.entries(JanColors).map(([name, janColor]) => {
                let distance = getDistance(janColor, searchHex);
                return new JanColor(name, distance)
            }), jc => jc.distanceSum);
            console.log(suggestionArr[0].name, suggestionArr[0].distanceSum)
            let result = '';
            if (!Number.isNaN(suggestionArr[0].distanceSum)) {
                for (let idx = 0; idx < 3; ++idx) {
                    const jC = suggestionArr[idx];
                    result += `${jC.name}(${jC.distanceSum})   `;
                }
            }

            this.setState({foundLessName: result});
        }

    }

    render() {
        const resultStyle = {
            "display": "inline-block",
            "paddingLeft": "1cm",
            "fontWeight": "bold",
        };
        return (
            <div>
                <p>Search by hex: </p>
                <span>
                    <input onChange={this.searchHex}/>
                </span>
                <span style={resultStyle} className="JacColorSugg">
                    {this.state.foundLessName}
                </span>
                <p>
                    Neue Funktion: Bitte in COP WebKompo. rückmelden ob es immer exakte Treffer gibt.
                </p>
            </div>
        );
    }
}

export default SearchByHex;
